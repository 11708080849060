import React, { Suspense, useState, useEffect } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';

import { NavBar, TopBar } from './components';
import useRouter from 'utils/useRouter';
import { useContext } from 'react';
import AppDataContext from 'Helper/AppDataContext';
import Api from 'Helper/ApiHandler';
var api = new Api();

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    position: 'relative',
    '& .makeStyles-root-31': {
      [theme.breakpoints.down('sm')]: {
        margin: '0',
        padding: '16px'
      }
    }
  },
  topBar: {
    zIndex: 2,
    position: 'absolute'
    // marginBottom: "5px"
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  navBar: {
    zIndex: 3,
    width: 250,
    minWidth: 256,
    flex: '0 0 auto',
    overflowX: 'hidden'
  },
  navBarSmall: {
    zIndex: 3,
    flex: '0 0 auto',
    width: '117px',
    overflowX: 'hidden'
  },
  content: {
    overflowY: 'auto',
    flex: '1 1 auto',
    paddingTop: '60px',
    overflowX: 'hidden'
  }
}));

const Dashboard = props => {
  const { route } = props;

  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const [openNav, setOpenNav] = React.useState(true);
  const { appContext, setAppContext } = useContext(AppDataContext);

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  const router = useRouter();

  useEffect(() => {
    console.log(route.routes, 'route.routes');
    onLoad();
  }, [router.location.pathname]);

  // console.log(appContext, 'appContext');
  const onLoad = () => {
    api
      .get('admin/countnotify')
      .then(result => {
        if (result.success === 'true') {
          console.log('call--');
          console.log(result, 'result');
          setAppContext({
            ...appContext,
            notificationCount: result.data
          });
        } else {
          console.log(result, 'error');
        }
      })
      .catch(err => {
        console.log(err, 'catch error');
      });
  };

  return (
    <div className={classes.root}>
      <TopBar
        className={classes.topBar}
        onOpenNavBarMobile={handleNavBarMobileOpen}
        openNav={openNav}
      />
      <div className={classes.container}>
        <NavBar
          className={openNav ? classes.navBar : classes.navBarSmall}
          onMobileClose={handleNavBarMobileClose}
          openMobile={openNavBarMobile}
          setOpenNav={setOpenNav}
          openNav={openNav}
        />
        <main className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
          </Suspense>
        </main>
      </div>
      {/* <ChatBar /> */}
    </div>
  );
};

Dashboard.propTypes = {
  route: PropTypes.object
};

export default Dashboard;
