import axios from 'axios';
import AsyncLocalStorage from '@createnextapp/async-local-storage';
// import Auth from './Auth';

const METHOD = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete'
};

// const BASEURL = process.env.REACT_APP_API_URL;

const BASEURL = process.env.REACT_APP_API_URL;
// const BASEURL = "http://159.65.151.13/administrator/api/";
// const BASEURL = "http://localhost/globtalk_backend/api/";
// const BASEURL = "https://administrator.globtalk.in/api/";
// const BASEURL = "http://192.168.1.35:8096/api/";
// const BASEURL = process.env.REACT_APP_API_URL;
// const BASEURL = 'http://192.168.29.75:8096/api/';
// const BASEURL = "https://devapi.openmaps.pro/api/";

// const BASEURL = "https://e2577xl97b.execute-api.sa-east-1.amazonaws.com/dev/";

// CHECK BELOW FOR SAMPLE DATA TO BE PASSED
class Api {
  isLoggedIn = false;

  constructor() {
    this.baseURL = BASEURL;
    this.isAlreadyFetchingAccessToken = false;
  }

  // URL FOR API
  // REFER SAMPLE JSON AT BOTTOM FOR DATA VALUES
  get(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.GET, url, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  post(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.POST, url, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  }

  put(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.PUT, url, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  }

  delete(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.DELETE, url, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  }

  api(method, url, data) {
    // this.getAuthenticationInfo();
    return AsyncLocalStorage.getItem('authTokenSuperAdmin').then(token => {
      // console.log(token);
      if (token && token !== '') {
        this.isLoggedIn = true;
        this.accessToken = token;
      }
      return new Promise((resolve, reject) => {
        let axiosConfig = {};
        axiosConfig.method = method;

        axiosConfig.url = this.baseURL + url;

        axiosConfig.headers = this.setHeaders(data);
        if (data) {
          if (data.params) axiosConfig.params = data.params;

          if (data.data) axiosConfig.data = data.data;
        }

        // console.log("axiosConfig", axiosConfig)
        axios(axiosConfig)
          .then(response => {
            // console.log(response.data);
            resolve(response.data);
          })
          .catch(error => {
            console.log('error', error);
            if (error.response && error.response.status === 401) {
              if (!this.isAlreadyFetchingAccessToken) {
                this.isAlreadyFetchingAccessToken = true;
                // const auth = new Auth();
                // auth.renewSession();
                const newUrl = axiosConfig.url.replace(BASEURL, '');
                this.api(axiosConfig.method, newUrl, axiosConfig.data);
              } else {
                localStorage.removeItem('authTokenSuperAdmin');
              }
            } else if (error.response) {
              console.log('ERROR', error.response);
              reject(error.response.data);
            }
          });
      });
    });
  }

  setHeaders(data) {
    let headers = {};
    headers['accept-language'] = 'en';
    headers['Content-Type'] = 'application/json';
    headers['Xapi'] = 'jwZryAdcrffggf867DnjhjhfRvsfhjs11222021';

    if (data) {
      if (data.isMultipart) {
        headers['Content-Type'] = 'multipart/form-data';
      }

      if (data.headers) {
        for (var key in data.headers) {
          if (data.headers.hasOwnProperty(key)) {
            headers[key] = data.headers[key];
          }
        }
      }
    }

    if (this.isLoggedIn !== false && !(data && data.skipAuth)) {
      // headers['Authorization'] = 'Bearer ' + this.accessToken;
      // headers['Authorization'] = this.accessToken;
      headers['x-access-token'] = this.accessToken;
    }

    return headers;
  }
}

// SAMPLE DATA JSON
/* let sample_data = {

    // ADDITIONAL HEADERS IF REQUIRED
    headers :{
        'Content-type'  : 'xx-urlencode',
    },

    // IF USER ID AND TOKEN SHOULDN'T BE PASSED IN HEADERS (USER FOR AFTER LOGIN API)
    // DEFAULT : FALSE;
    skipAuth    : false,

    // IF Default error handling needs to be overridden
    // DEFAULT : FALSE;
    skipErrorHandling    : false,

    // FOR SENDING FILES OR FORM DATA REQUEST
    isMultipart : true,

    // `PARAMS` ARE THE URL PARAMETERS TO BE SENT WITH THE REQUEST
    params : {
        user_id     : 10,
        name        : "lorem",
        page        : 3,
        sort_by     : 'name'
    },

    // POST DATA
    data : {
        firstName   : 'Lorem',
        lastName    : 'Ipsum'
    },
} */

export default Api;
